import React from "react";
import { Link } from "gatsby";
import { useState } from "react";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      prevScrollpos: "",
    };
  }

  handleClick(event) {
    event.preventDefault();
    this.state.toggle ? this.setState({ toggle: false }) : this.setState({ toggle: true });
  }

  render() {
    const navClass = this.state.toggle
      ? "nav-container -active"
      : "nav-container";
    return (
      <>
        <nav className={navClass}>
          <div className="nav-wrapper">
            <ul
              className={
                this.props.active === "all" ? "nav-inner -active" : "nav-inner"
              }
            >
              <li>
                <Link
                  className="nav-link"
                  partiallyActive={true}
                  activeClassName="-active"
                  to="/firm"
                >
                  Firm
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  partiallyActive={true}
                  activeClassName="-active"
                  to="/people"
                >
                  People
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  partiallyActive={true}
                  activeClassName="-active"
                  to="/expertise"
                >
                  Expertise
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  partiallyActive={true}
                  activeClassName="-active"
                  to="/news"
                >
                  News
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  partiallyActive={true}
                  activeClassName="-active"
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-links-container">
            <Link className="footer-link" to={`/news`}>
              News
            </Link>
            <Link className="footer-link" to={`/terms`}>
              Terms of use
            </Link>
          </div>
        </nav>
        <a
          className={
            this.state.toggle ? "nav-hamburger -active" : "nav-hamburger"
          }
          name="Mobile Nav Toggle"
          onClick={e => this.handleClick(e)}
        >
          <div className="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </>
    );
  }
}

export default Nav;
