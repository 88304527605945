import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import "../styles/main.css";
import logoLarge from "../images/logo-large.svg";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import LogoSvg from "../helper/LogoSvg";

const Layout = ({ children, className, active, backgroundImage }) => {
  const location = useLocation().pathname;
  const style = backgroundImage
    ? {
        backgroundImage: `linear-gradient(black, black), url(${backgroundImage.src})`,
      }
    : null;
  return (
    <div
      className={`site-container${style ? " -bg" : ""}${
        location !== "/" ? " -alt" : ""
      }`}
      style={style}
    >
      <div className="logo-aside">
        <Link
          to="/"
          className={`logo-aside-icon${location === "/" ? " -home" : ""}`}
        >
          <LogoSvg />
        </Link>
      </div>
      <div className="page-inner">
        <Nav />
        <main className={`main ${className}`}>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
