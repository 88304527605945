import React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <footer>
    <div className="footer-inner">
      <div className="footer-links-container">
        <Link
          className="footer-link"
          to={`/terms`}
          partiallyActive={true}
          activeClassName="-active"
        >
          Terms of use
        </Link>
      </div>
    </div>
  </footer>
);

export default Footer;
