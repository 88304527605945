import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image }) => (
  <StaticQuery
    query={graphql`
      query SeoQuery {
        allSanitySiteSettings {
          edges {
            node {
              pageSEO {
                metaTitle
                metaDescription
                metaImage {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `}
    render={data => {
      const site = data.site.siteMetadata;
      data = data.allSanitySiteSettings.edges[0].node.pageSEO;

      const { metaTitle, metaDescription, metaImage } = data;

      let imageFile = "";
      if (image && image.asset) {
        imageFile = image.asset.url;
      } else if (metaImage && metaImage.asset) {
        imageFile = metaImage.asset.url;
      } else {
        imageFile = `${site.siteUrl}/static/opengraph.png`;
      }

      const seo = {
        title: `Simpsons | ${title || metaTitle || site.title}`,
        description: description || metaDescription || site.description,
        image: imageFile,
      };

      return (
        <>
          <Helmet
            title={seo.title}
            htmlAttributes={{
              lang: "en",
            }}
            meta={[
              {
                name: `description`,
                content: seo.description,
              },
              {
                property: `og:title`,
                content: seo.title,
              },
              {
                property: `og:description`,
                content: seo.description,
              },
              {
                property: `og:image`,
                content: seo.image,
              },
              {
                property: `og:image:width`,
                content: 1200,
              },
              {
                property: `og:image:height`,
                content: 630,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
            ]}
          ></Helmet>
        </>
      );
    }}
  />
);

export default SEO;
